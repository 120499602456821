import React from 'react'
import PropTypes from 'prop-types'

export default function StatusBar({status, active}) {
  return (
    <section className="statusbar container">
      <ul>
        {
          status.map((info, key) => {
            let condition
            if(key < active) {
              condition = "done"
            } else if(key === active) {
              condition = "active"
            } else {
              condition = "pending"
            }
            return (
              <li className={condition} key={key}>{info}</li>
            )
          })
        }
      </ul>
    </section>
  )
}
StatusBar.propTypes = {
  status: PropTypes.array.isRequired,
  active: PropTypes.number
}