import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { get_prodcategory, getui } from '../../controller'
import { cate_key } from '../../redux-actions/action'
import ImgFake from '../fakeimg'

export default function Sidebar() {
  const lang = useSelector(state => state.lang)
  const location = useLocation()
  const uitext = useSelector(state => state.translate)
  const [active, setactive] = useState(null)
  const [categories, setcategories] = useState(null)
  const dispatch = useDispatch()
  const catekey = useSelector(state => state.catekey)
  const history = useHistory()
  useEffect(() => {
    get_prodcategory({
      lang: lang
    })
    .then(res => {
      setcategories(res)})
    // get para category key for set active
    let brandUrl = window.location.hash
    brandUrl === '#brandlisting' ? catekey !== "null" && setactive(Number(catekey)) : setactive(null)
  }, [setcategories, setactive])


  useEffect(() => {
    let url = window.location.hash.replace(/#/ig, '')
    if(url !== "") {
      console.log(active, Number(url))
      if(active !== Number(url)) {
        window.catname = url
        setactive(Number(url))
      }
    }
  })

  const handleClick = (key, {...info}) => {
    if (info.sub_category.length !== 0) {
      dispatch(cate_key(key))
      if (key === active) {
        setactive(null)
      } else {
        history.push(location.pathname + '#' + key)
      }
    } else {
      dispatch(cate_key(null))
      window.location.href = window.location.origin + `/brandlisting/${info.special_category ? 'special' : 'normal'}/${info.category_id}/${info.category_name}`
    }
  }

  
  return (
    <div className='sidebar'>
      <h2>{getui(uitext, 'product_category', lang)}</h2>
      <div 
      className={'sidebar-hover ' + (active !== null ? 'active' : '')}
      onClick={() => {
        setactive(null)
        history.push("/");
      }}
      >
      <button><img src={require('../../assets/img/close.svg')} alt='close' /></button>
      </div>
      <ul className='sidebar-list'>
        {
          categories !== null &&
          categories.map((info, key) => (
            <li 
            key={key} 
            className={"side " + (active === key ? "active" : "")}
            data-cat={`#${key}`}
            onClick={
              () =>  {
                if(active !== key) {
                  handleClick(key, {...info})
                } else {
                  console.log(location)
                  history.push('/')
                  setactive(null)
                }
              }

              // info.sub_category.length !== 0 ? 
              //   () => key === active ? setactive(null) : setactive(key) :
              //   () => {
              //     window.location.href = window.location.origin + `/brandlisting/${info.special_category ? 'special' : 'normal'}/${info.category_id}/${info.category_name}`
              //   }
              }
            >
              <p>
                <span className='sidebar-img-bg'>
                  <span className='sidebar-img' style={{background: `url('${info.category_image || require('../../assets/img/no-img.jpg')}') no-repeat center / contain`}}>
                    { 
                      window.innerWidth >= 992 ? 
                      <ImgFake width={24} height={24} unresponsive/> :
                      <ImgFake width={24} height={24} />
                    }
                  </span>
                </span>
                <span className='sidebar-text'>{info.category_name}</span>
              </p>
              {
                info.sub_category.length !== 0 &&
                <>
                
                <ul className='mega-menu'>
                <h3 className='mega-title'>{info.category_name}</h3>
                {
                  info.sub_category.map((megainfo, key) => 
                  <li className='mega-item' key={key}>
                    <Link to={'/brandlisting/' + `${megainfo.special_category ? 'special/' : 'normal/'}` + encodeURIComponent(megainfo.category_id) + '/' + encodeURIComponent(megainfo.category_name) } className='mega-link' 
                    onClick={() => window.location.href = window.location.origin + '/brandlisting/' + `${megainfo.special_category ? 'special/' : 'normal/'}` + encodeURIComponent(megainfo.category_id) + '/' + encodeURIComponent(megainfo.category_name)}>
                      <p>
                        <span className='sidebar-img-bg'>
                          <span className='mega-img' style={{background: `url('${megainfo.category_image || require('../../assets/img/no-img.jpg')}') no-repeat center / contain`}}>
                            {
                              window.innerWidth >= 992 ? 
                              <ImgFake width={24} height={24} unresponsive/> :
                              <ImgFake width={24} height={24} />
                            }
                            
                          </span>
                        </span>
                        <span className='mega-text'>{megainfo.category_name}</span>
                      </p>
                    </Link>
                  </li>
                  )
                }
              </ul>
              </>
              }
            </li>
          
          ))
        }
      </ul>
    </div>
  )
}
