export default {
  title: 'Hottest Item',
  data: [
    {
      img: require('../assets/img/5.png'),
      label: 'Product One',
      unit: 'KS',
      price: 30000.00,
      patch: '/',
      step: 1,
    },
    {
      img: require('../assets/img/5.png'),
      label: 'Product One',
      unit: 'KS',
      price: 3000.00,
      patch: '/'
    },
    {
      img: require('../assets/img/5.png'),
      label: 'Product One',
      unit: 'KS',
      price: 3000.00,
      patch: '/'
    },
    {
      img: require('../assets/img/5.png'),
      label: 'Product One',
      unit: 'KS',
      price: 3000.00,
      patch: '/'
    },
    {
      img: require('../assets/img/5.png'),
      label: 'Product One',
      unit: 'KS',
      price: 3000.00,
      patch: '/'
    },
    {
      img: require('../assets/img/5.png'),
      label: 'Product One',
      unit: 'KS',
      price: 3000.00,
      patch: '/'
    },
    {
      img: require('../assets/img/5.png'),
      label: 'Product One',
      unit: 'KS',
      price: 3000.00,
      patch: '/'
    },
    {
      img: require('../assets/img/5.png'),
      label: 'Product One',
      unit: 'KS',
      price: 3000.00,
      patch: '/'
    }
  ]
}