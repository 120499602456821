import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Breadcrumb from '../../components/breadcrumb'
import parse from 'html-react-parser'
import { get_policy } from '../../controller'
import './style.scss'

export default function Policy() {
  const lang = useSelector(state => state.lang)
  const [ policy, setpolicy ] = useState(null)
  useEffect(() => {
    get_policy({
      lang: lang
    })
    .then(res => res.length > 0 && setpolicy(res[0]))
    window.scrollTo(0, 0)
  }, [setpolicy])
  return (
    policy !== null &&
    <article id='policy'>
      <Breadcrumb pagename={policy.name} links={[
        {
          name: policy.name
        }
      ]}/>
      <section className='policy-wrap'>
        <div className='container'>
          <div className='row'>
            {
              <div className='col-lg-12'>
                <div className='policy-item'>
                  
                  <>
                    {parse(policy.privacy_description)}
                  </>
                </div>
              </div>              
            }              
          </div>
        </div>
      </section>
    </article>
  )
}
