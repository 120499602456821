import config from './_config'
const axios = require('axios')

export const post_changepass = (access_token,data) => {
  return axios.create({
    headers: {
      'access-token': access_token
    }
  })
  .post(`${config.url}/user/change_password`, data)
  .then(res => res.data.result)
  .catch(err => err)
}