const config = require('./_config')
const axios = require('axios')

export const get_delivery = (param) => {
  return axios.get(`${config.default.url}/list/delivery/carrier`, {
    params: {
      ...param
    }
  })
  .then((res) => res.data.data)
  .catch(() => "server error")
}