import React from 'react'
import PropTypes from 'prop-types'
export default function Button({text, play, addclass, lfticn, rhticn}) {
  return (
    <button 
    onClick={play} 
    className={"cusbtn " + (addclass || "")}>
      { lfticn && <img src={lfticn} alt="sb baby" className="icn-lft"/> }
      {text}
      { rhticn && <img src={rhticn} alt="sb baby" className="icn-rht"/> }
    </button>
  )
}
Button.propTypes = {
  text: PropTypes.string.isRequired,
  play: PropTypes.func,
  addclass: PropTypes.string,
  lfticn: PropTypes.string,
  rhticn: PropTypes.string
}