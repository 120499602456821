import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ins } from "../../redux-actions/action";
import { useParams } from "react-router-dom";
import Button from "../../components/button";
import ProductSlider from "../../components/productslider";
import {
  get_proddetail,
  get_exit_wish_list,
  post_add_wish_list,
  post_remove_wish_list,
  get_productqty,
  get_prodlist,
} from "../../controller";
import Spanner from "../../components/spanner";
import { Link } from "react-router-dom";
import data from "../../data";
import _ from "underscore";
import currency from "currency.js";
import config from "../../config";
import { calc_percent } from "../../utils/discount_calc";
const { cart } = data;

export default function ProductInfo({ setCatId, setCagName }) {
  const lang = useSelector((state) => state.lang);
  const login = useSelector((state) => state.login);
  const redux_cart = useSelector((state) => state.cart);
  const [info, setinfo] = useState(null);
  const [attr_dict, set_attr_dict] = useState(null);
  const [user_attr, set_user_attr] = useState(null);
  const [price, set_price] = useState(null);
  const [added_qty, set_added_qty] = useState(1);
  const [attr_id, set_attr_id] = useState(null);
  const [attr_str, set_attr_str] = useState(null);
  const [wish_list_icon_active, set_wish_list_icon_active] = useState(null);
  const [imglink, setimglink] = useState(null);
  const [checkstock, setCheckStock] = useState(null);
  const [error, seterror] = useState(false);
  const [outofstock, setoutofstock] = useState(false);
  const [stock, setStock] = useState(false)
  // const [prodDesc, setprodDesc] = useState(null)
  let id = useParams().productid;
  let product_attribute = {};
  let user_attribute = {};
  let user_price = 0;
  // useEffect(() => {
  //   if(info !== null) {
  //     let text = info.description
  //     let prodText = text.replace(/(?:\r\n|\r|\n)/g, '<br/>')
  //     setprodDesc(prodText)
  //   }
  // })
  useEffect(() => {
    get_proddetail({
      product_id: id,
      lang: lang,
    }).then((res) => {
      setCheckStock(res.status);
      res.status === 'in_stock' ? setStock(false) : setStock(true)
      // console.log(res.status)
      setCagName(res.category_name);
      console.log(res.name, 'ga <<<<')
      window.gtag('config', 'G-GTX02WJT84', { 'page_title': res.name, page_path: window.location.pathname + window.location.search })
      setinfo(res);
      setimglink(res.gallery);
      user_price += res.list_price;
      let attr_list = res.attribute_list;
      for (const attr of attr_list) {
        product_attribute[attr.product_attribute] = {};
        for (const [i, data] of attr.attributes.entries()) {
          if (i === 0) {
            user_attribute[attr.product_attribute] = data.id;
            user_price += data.price;
          }
          product_attribute[attr.product_attribute][data.id] = data.price;
        }

        set_attr_id(attr.attributes[0].id);
      }
      set_attr_dict(product_attribute);
      set_user_attr(user_attribute);
      set_price(user_price);
      change_attr_to_str(user_attribute);
      get_prodlist({
        categ_id: res.category_id,
      }).then((response) => {
        let data = _.sample(
          _.filter(
            response.product_list,
            (data) => data.product_id !== Number(id)
          ),
          4
        );
        // console.log(data, '.... data')
        setCatId(data);
      });
      login !== null && check_exit_wish_list(user_attribute);
    });
  }, [setimglink, id]);

  const calc_price = (e) => {
    let attr = user_attr;
    attr[e.group] = e.id;
    let price = info.list_price;
    for (const key in attr) {
      if (attr.hasOwnProperty(key)) {
        price += attr_dict[key][attr[key]];
      }
    }
    set_price(price);
    set_user_attr(attr);
    set_attr_id(attr[e.group]);
    change_attr_to_str(attr);
    setoutofstock(false);
    if (login !== null) check_exit_wish_list(attr);
  };

  const change_attr_to_str = (user_attr) => {
    let combine_attr = [];
    _.each(user_attr, (i) => combine_attr.push(i));
    let combine_attr_str = combine_attr.toString(); // "4,1,8"
    set_attr_str(combine_attr_str);
  };

  let dispatch = useDispatch();
  const check_exit_wish_list = (user_attr) => {
    let combine_attr = [];
    _.each(user_attr, (i) => combine_attr.push(i));
    let combine_attr_str = combine_attr.toString(); // "4,1,8"
    get_exit_wish_list(
      {
        user_id: login.userid,
        product_id: id,
        variant_id: combine_attr_str,
      },
      login.access_token
    ).then((res) => {
      if (res.status === "Exist!") {
        set_wish_list_icon_active(true);
      } else {
        set_wish_list_icon_active(false);
      }
    });
  };

  const add_to_wish_list = () => {
    if (login === null) {
      seterror(true);
    } else {
      let combine_attr = [];
      _.each(user_attr, (i) => combine_attr.push(i));
      let combine_attr_str = combine_attr.toString(); // "4,1,8"
      if (wish_list_icon_active) {
        post_remove_wish_list(
          id,
          combine_attr_str,
          login.userid,
          login.access_token
        ).then((res) => {
          if (res.status === "Success!") {
            set_wish_list_icon_active(false);
          } else {
            set_wish_list_icon_active(true);
          }
        });
      } else {
        post_add_wish_list(
          id,
          combine_attr_str,
          login.userid,
          login.access_token
        ).then((res) => {
          // console.log(res, '.../')
          if (res.status === "Success!" || res.status === "Duplicated!") {
            set_wish_list_icon_active(true);
          } else {
            set_wish_list_icon_active(false);
          }
        });
      }
    }
  };
  const add_to_cart = () => {
    let uniqueid = id + attr_str;
    let current_qty =
      _.find(redux_cart, (item) => item.uniqueid === uniqueid) &&
      _.find(redux_cart, (item) => item.uniqueid === uniqueid).product_uom_qty;
    get_productqty({
      product_id: id,
      variant_id: attr_str,
      qty: (current_qty || 0) + added_qty,
    }).then((res) => {
      if (res.instock) {
        const { name, url_link, description, percentage } = info;
        let varient_id = [];
        if (Object.keys(user_attr).length !== 0) {
          for (const key in user_attr) {
            if (user_attr.hasOwnProperty(key)) varient_id.push(user_attr[key]);
          }
        }
        let product = {
          product_id: id,
          name,
          img: url_link,
          description,
          price,
          discount_price: calc_percent(price, 100 - percentage),
          percentage,
          tax: "",
          varient_id: varient_id.toString(),
          varient_name: user_attr,
          product_uom_qty: added_qty,
          uniqueid: id + varient_id.toString(),
        };
        let attrname = [];
        _.map(user_attr, (value, key) => {
          _.map(info.attribute_list, (get_attr) => {
            _.find(
              get_attr.attributes,
              (i) => i.id === value && attrname.push(i)
            );
          });
        });
        product.varient_name = attrname;
        dispatch(ins(product));
      } else {
        setoutofstock(true);
      }
    });
  };

  return (
    <div className="row">
      <div className="col-lg-6">
        {imglink !== null && (
          <div className="product-gallery">
            {imglink.length === 0 ? (
              <img
                className="item-img"
                src={info.url_link || require("../../assets/img/no-img.jpg")}
                alt={info.name}
              />
            ) : (
              <ProductSlider imglink={imglink} />
            )}
          </div>
        )}
      </div>
      <div className="col-lg-6">
        {info !== null && (
          <div className="product-info">
            <h2 className="title">
              {decodeURIComponent(info.name)} <small>{info.sku}</small>
            </h2>
            {info.description !== null && (
              <pre className="desc">
                {
                  info.description
                }
              </pre>
            )}
            <p className="price">
              {info.percentage > 0 && (
                <small>
                  <del>
                    {/* {currency(info.list_price, config.currency).format()} */}
                    {currency(price, config.currency).format()}
                  </del>
                </small>
              )}
              {" " +
                currency(
                  calc_percent(price, 100 - info.percentage),
                  config.currency
                ).format()}
            </p>
            <div className="variant">
              {info.attribute_list.map((value, key) => (
                <div className="color" key={key}>
                  <h3>
                    <strong>{value.product_attribute}</strong>
                  </h3>
                  {value.attributes.map((attr, key) => (
                    <div className="color-align" key={key}>
                      <label>
                        <input
                          type="radio"
                          name={value.product_attribute}
                          value={`${value.attribute_id}-${attr.id}`}
                          defaultChecked={key === 0}
                          onChange={() =>
                            calc_price({
                              group: value.product_attribute,
                              ...attr,
                            })
                          }
                        />
                        {attr.name}
                      </label>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {checkstock === "in_stock" && (
              <div className="price-qty">
                <p className="quantity">
                  <strong>Quantity</strong>
                </p>
                <Spanner
                  attrId={attr_id}
                  prodId={id}
                  value={added_qty}
                  change={(qty) => set_added_qty(qty)}
                  check_qty={false}
                />
              </div>
            )}
          </div>
        )}
        <p className="checkstock">{outofstock && "Temporarily out of stock"}</p>
        {checkstock === "in_stock" ? (
          <div className="addto">
            <Button
              text="Add To Cart"
              play={() => add_to_cart()}
              addclass="btn-addto"
            />
          </div>
        ) : (
          <p className="checkstock">{stock && "Out of stock"}</p>
        )}
        <div className="wishlist">
          <Button
            text="Add to wishlist"
            lfticn={
              wish_list_icon_active === true
                ? require("../../assets/img/heart-red.svg")
                : require("../../assets/img/heart.svg")
            }
            play={() => add_to_wish_list()}
            addclass="btn-wishlist"
          />
          {error && (
            <p className="text-small text-danger">
              Please login to add product to wishlist.{" "}
              <Link to="/login">Login</Link>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
