import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ins, open_popup } from '../../redux-actions/action'
import _ from 'underscore'
import Table from '../../components/table'
import Button from '../../components/button'
import Breadcrumb from '../../components/breadcrumb'
import { addToCart } from '../../utils/add_to_cart'
import { calc_percent } from '../../utils/discount_calc'
import { get_wish_list, post_remove_wish_list, getui } from '../../controller'
import './style.scss'

export default function Wishlist() {
  const uitext = useSelector(state => state.translate)
  const lang = useSelector(state => state.lang)
  const dispatch = useDispatch()
  const login = useSelector(state => state.login)
  login === null && (window.location.href = '/login')
  const [wish_list, set_wish_list_all] = useState(null)
  useEffect(() => {
    get_all_wish_list()
    window.scrollTo(0, 0)
  }, [])
  const get_all_wish_list = () => {
    get_wish_list({
      user_id: login.userid
    }, login.access_token)
      .then((res) => {
        set_wish_list_all(res)
      })
      .catch(err => window.location.href = '/')
  }

  const remove_wish_list = (product) => {
    post_remove_wish_list(product.product_id, product.selected_variant === false ? "" : product.selected_variant, login.userid, login.access_token).then((res) => {
      if (res.status === 'Success!') {
        get_all_wish_list()
      }
    })
  }
  return (
    <article id="wishlist">
      <Breadcrumb pagename={getui(uitext, 'wishlist', lang)} links={[
        {
          name: getui(uitext, 'wishlist', lang)
        }
      ]
      } />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {
              wish_list !== null && wish_list.length === 0 &&
              <>
                <h2>There are no items on your list.</h2><br />
                <Button text="Browse Items" play={() => window.location.href = '/'}/>
                <br/><br/><br/>
              </>
            }
            {
              wish_list !== null && wish_list.length > 0 &&
              <Table
                heads={["PRODUCTS", "PRICE", "", ""]}
                body={
                  wish_list !== null && wish_list.map((wish_data, key) => {
                    let attr_variants = []
                    wish_data.attribute_value_list.map((attr) => {
                      attr_variants.push(attr.name)
                    })
                    let attr_str = attr_variants.toString();
                    return (
                      <tr key={key}>
                        <td>
                          <div className="product">
                            <div className="desc">
                              <p>{wish_data.name}</p>
                              {
                                wish_data.attribute_value_list.length > 0 &&
                                <small className="text-small text-secondary">{
                                  _.map(wish_data.attribute_value_list, data => data.name).toString()
                                }</small>
                              }
                            </div>
                          </div>
                        </td>
                        <td>{wish_data.list_price}</td>
                        <td>
                          <Button
                            text="Add to Cart"
                            play={() => addToCart(wish_data.id || wish_data.product_id).then(res => {
                              if (res !== 'out of stock') {
                                let data = {
                                  description: res.description,
                                  img: res.url_link,
                                  name: res.name,
                                  product_id: (wish_data.id || wish_data.product_id).toString(),
                                  product_uom_qty: 1,
                                  tax: "",
                                  varient_id: _.map(wish_data.attribute_value_list, data => data.id).toString(),
                                  varient_name: wish_data.attribute_value_list,
                                  percentage: res.percentage
                                }
                                data.price = _.reduce(
                                  _.map(res.selected_varient, data => data.price),
                                  (memo, num) => memo + num, wish_data.price || wish_data.list_price)
                                data.discount_price = calc_percent(data.price, 100 - data.percentage)
                                dispatch(ins(data))
                              } else {
                                dispatch(open_popup(
                                  {content : 
                                    <div className="alert-out-of-stock">
                                      <h2>This item is out of stock.</h2>
                                    </div>
                                  }
                                ))
                              }
                            })}
                            addclass="btn-addto"
                          />
                        </td>
                        <td>
                          <img
                            src={require('../../assets/img/delete-button.png')}
                            alt="remove sbbaby product from cart"
                            className="remove-item"
                            onClick={() => remove_wish_list(wish_data)}
                          /></td>
                      </tr>
                    )
                  })
                }
              />
            }

          </div>

        </div>
      </div>
    </article>
  )
}
