const login = (state = JSON.parse(localStorage.getItem('mobile')), action) => {
  switch (action.type) {
    case 'MOBILE' :
      localStorage.setItem('mobile', JSON.stringify(action.data))
      return state
    default :
      return state
  }
}

export default login