import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Breadcrumb from '../../components/breadcrumb'
import parse from 'html-react-parser'
import { get_about, getui } from '../../controller'
import './style.scss'

export default function About() {
  const lang = useSelector(state => state.lang)
  const uitext = useSelector(state => state.translate)
  const [ about, setabout ] = useState(null)
  useEffect(() => {
    get_about({
      lang: lang
    })
    .then(res => setabout(res))
    window.scrollTo(0, 0)
  }, [setabout])
  
  return (
    about !== null &&
    <article id='about'>
      
      <Breadcrumb pagename={getui(uitext, 'about_us', lang)} links={[
        {
          name: getui(uitext, 'about_us', lang)
        }
      ]}/>
      <section className='about-wrap'>
        <div className='container'>
          
            <div className='row'>
              <div className='col-lg-6'>
                <img src={about[0].aboutus_image} alt={about[0].aboutus_name} />
              </div>
              <div className='col-lg-6'>
                <h2>{about[0].aboutus_name}</h2>
                <>{parse(about[0].aboutus_description)}</>
              </div>
            </div>
        </div>
      </section>
    </article>
  )
}
