// static data collection
import languages from './language'
import socials from './social'
import general from './general'
import navlink from './navlink'
import fcontact from './footcontact'
import finfo from './footerinfo'
import sidebar from './sidebar'
import promo from './promotion'
import cartstatus from './cartstatus'
import cart from './cart'
import cardinfo from './card'
import share from './share'
import about from './about'
import policy from './policy'
import terms from './terms'
import profile from './profile'
import orderhistory from './orderhistory'
import brand from './brand'
import banner from './banner'

export default {
    languages,
    socials,
    general,
    navlink,
    fcontact,
    finfo,
    sidebar,
    promo,
    cartstatus,
    cart,
    cardinfo,
    share,
    about,
    policy,
    terms,
    profile,
    orderhistory,
    brand,
    banner
}