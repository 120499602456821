const config = require('./_config')
const axios = require('axios')

export const get_orderdetail = (orderid, access_token) => {
  return axios.create({
    headers: {
      'access-token': access_token
    }
  })
  .get(`${config.default.url}/sale_order/detail?order_id=${orderid}`)
  .then(res => res.data.data)
  .catch(() => "server error")
}