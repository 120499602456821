const config = require('./_config')
const axios = require("axios");
export const post_add_wish_list = (
  product_id,
  variant_id,
  user_id,
  access_token
) => {
  return axios
    .create({
      headers: {
        "access-token": access_token,
      },
    })
    .post(
      // `http://admin.sbbabyshop.com/wishlist/add?user_id=${user_id}&product_id=${product_id}&variant_id=${variant_id}`
      `${config.default.url}/wishlist/add?user_id=${user_id}&product_id=${product_id}&variant_id=${variant_id}`
    )
    .then((res) => res.data.data)
    .catch((err) => err);
};
