import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../../components/button'
import { useSelector } from 'react-redux'

export default function ProfileSection({profile}) {
  const mobile = useSelector(state => state.mobile)

  return (
    <section className="section-profile">
      <ul className='profile-list'>
          <li>
            {/* <img src={require('../../assets/img/close1.png')} alt="no item"/> */}
            <span>Name</span>
            <span>{profile[0].name}</span>
          </li>
          <li>
            <span>Email</span>
            <span>{profile[0].email}</span>
          </li>
          <li>
            <span>Phone</span>
            <span>{profile[0].mobile}</span>
          </li>
          <li>
            <span>Address</span>
            <span>{profile[0].street}</span>
          </li>
          <li>
            <span>State</span>
            <span>{profile[0].state}</span>
          </li>
          <li>
            <span>City</span>
            <span>{profile[0].city}</span>
          </li>
      </ul>
      <div className='editbtn-wrap'>
        <Link to='/edituserprofile' className='cusbtn update-btn'>Edit Profile</Link>
        <Link to='/changepass' className='cusbtn update-btn'>Change Password</Link>
      </div>
    </section>
  )
}
