import React, { useState } from 'react'
import Button from '../../components/button'
import Input from '../../components/fromdata'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './style.scss'
import { post_changepass, delete_logout, getui } from '../../controller'

export default function Changepass() {
    const lang = useSelector(state => state.lang)
    const uitext = useSelector(state => state.translate)
    const login = useSelector(state => state.login)
    login === null && (window.location.href='/login')
    let access_token = (login.access_token)
    const [ password, setpassword ] = useState(null)
    const [ error, setError ] = useState(null)

    const handleChange = (e) => {
        setpassword({
            ...password,
            [e.target.id]: e.target.value
        })
    }

    const removeLogin = () => {
      localStorage.removeItem('login')
      window.location.href= '/success/changepass'
    }

    const changeSuccess = () => {
      delete_logout(access_token)
      .then(res => {
        let logout_data = res[0].delete
        logout_data === true ?
        removeLogin() :
        console.log("")
      })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        password.password === password.conpass ?
        post_changepass(access_token, password)
        .then((res) => {
            res.status === 'success' ?
            changeSuccess() :
            setError('Invalid password format.')
        })
        :
        setError("Please make sure your password match!")
    }
    
    return (
      <article id="otp">
        <section className="bg-form">
          <div className="container form-container">   
            <div className="row">
              <div className='col-12'>
                <h2>{getui(uitext, 'change_pass', lang)}</h2>
                <div className="formsection">
                  <form onSubmit={handleSubmit}>
                    <div className="form-password">
                      <p>New Password</p>
                      <Input id="password" type="password" placeholder="New Password" play={(e) => handleChange(e)} required />
                    </div>
                    <div className="form-password">
                      <p>Confirm New Password</p>
                      <Input id="conpass" type="password" placeholder="Confirm New Password" play={(e) => handleChange(e)} required />
                    </div>
                    <p className='error'>{error}</p>
                    <Button
                      text='Submit'
                      addclass="btn-checkout btn-login"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
    )
}