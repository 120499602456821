import React from 'react'
import PropTypes from 'prop-types'

export default function Table({heads, body}) {
  return (
    <table className="table">
      <thead>
        <tr>
          {
            heads.map((head, key) => (
              <th key={key}>{head}</th>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {body}
      </tbody>
    </table>
  )
}
Table.propTypes = {
  heads: PropTypes.array.isRequired
}