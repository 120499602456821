const login = (state = JSON.parse(localStorage.getItem('login')), action) => {
  switch (action.type) {
    case 'LOGIN' :
      localStorage.setItem('login', JSON.stringify(action.data))
      return action.data
    default :
      return state
  }
}

export default login