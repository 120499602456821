export default {
  data: [
    {
      img: require('../assets/img/banner1.jpg'),
      path: '/'
    },
    {
      img: require('../assets/img/banner2.jpg'),
      path: '/'
    },
    {
      img: require('../assets/img/banner1.jpg'),
      path: '/'
    }
  ]
}