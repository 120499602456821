import React, { useState, useEffect } from 'react'
import FacebookLogin from 'react-facebook-login'
import { useDispatch, useSelector } from 'react-redux'
import { login_action } from '../../redux-actions/action'
import Button from '../../components/button'
import Input from '../../components/fromdata'
import { Link, useHistory } from 'react-router-dom'
import './style.scss'
import { post_login, get_profile, post_fblogin, getui } from '../../controller'

export default function Login() {
    const uitext = useSelector(state => state.translate)
    const lang = useSelector(state => state.lang)
    const [login, setLogin] = useState({})
    const [error, setError] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleChange = (e) => {
        setLogin({
            ...login,
            [e.target.id]: e.target.value
        })
    }
    let dispatch = useDispatch()
    let history = useHistory()
    const loginSuccess = (token, uid, expires) => {
        get_profile(uid, token)
        .then(res => {
            dispatch(login_action({
                ...res,
                userid: uid,
                access_token: token,
                expire: expires
            }))
            history.goBack(-1)
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        post_login(login)
        .then((res) => {
            res !== 'server error' ? 
            loginSuccess(res.access_token, res.uid, res.expires_in) : 
            setError(true)
        })
    }

    const fbLogin = (data) => {
        if(data.status === 'unknown') {
            return null
        }
        post_fblogin({
            oauth_uid: Number(data.userID), 
            oauth_access_token: data.accessToken, 
            name: data.name
        })
        .then((res) => {
            // console.log(res, '...')
            // console.log(res.status)
            res?.status === 'success' ?
            loginSuccess(res.access_token, res.uid, res.expires_in) :
            setError(true)
        })
    }
    
    return (
        <article id="login">
            <section className="bg-form">
                <div className="container form-container">
                    <div className="formsection">
                        <h2>{getui(uitext, 'sign_in', lang)}</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-name">
                                <p>Phone</p>                                
                                <Input id="name" type="text" placeholder="Phone" play={(e) => handleChange(e)} required/>
                            </div>

                            <div className="form-password">
                                <p>Password</p>                                
                                <Input id="pass" type="password" placeholder="Password" play={(e) => handleChange(e)} required/>
                            </div>

                            <p className='error'>{error && 'Invalid login name or password'}</p>
                            
                            <Button  
                                text="LOGIN"            
                                addclass="btn-checkout btn-login"
                            />
                            <div className="change-register">
                                <a href='/signup' className='change-pass'>{getui(uitext, 'sign_up', lang)}</a>
                                <Link to='/usercheck' className='change-pass'>{getui(uitext, 'forget_pass', lang)}</Link>
                            </div>
                            
                        </form>
                        <hr/>
                        <div className='fb-btn-wrap'>
                            <Button 
                                text="LOGIN WITH FACEBOOK"
                                lfticn={require('../../assets/img/facebook-blue.svg')}
                                play={() => {alert('Login Successfully')}}
                                addclass="btn-checkout btn-socailfb"
                            />   
                            <FacebookLogin  
                                appId="4933068516764623"
                                autoLoad={false}
                                fields="name, email, picture"
                                scope="public_profile"
                                callback={(getLoginStatus) => fbLogin(getLoginStatus)}
                            />
                        </div>
                        {/* <p className='change-pass' onClick={changePass}>Change Password</p> */}
                        
                    </div>
                </div>
            </section>
        </article>
    )
}