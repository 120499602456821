import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Breadcrumb from '../../components/breadcrumb'
import StatusBar from '../../components/statusbar'
import Review from './_review'
import Address from './_address'
import Confirm from './_confirm'
import Success from './_success'
import './style.scss'
import data from '../../data'
import { get_state, get_payment, getui, get_delivery } from '../../controller'
import _ from 'underscore'
const { cartstatus } = data

export default function ShoppingCart() {
  const uitext = useSelector(state => state.translate)
  const lang = useSelector(state => state.lang)
  const [cart_state, setcart_state] = useState(0)
  const [state, setstate] = useState(null)
  const [success] = useState(null)
  const [delivery, setdelivery ] = useState(null)
  const [payment, setpayment] = useState(null)
  const go_next_state = (state) => {
    setcart_state(state)
  }
  useEffect(() => {
    get_state()
    .then(res => {
      setstate(res)})
    get_payment()
    .then(res => setpayment(res))
    get_delivery({
      lang: lang
    })
    .then(res => setdelivery(res))
    window.scrollTo(0, 0)
  }, [setstate,setpayment,setdelivery])
  

  return (
    <article id="shoppingcart">
      <Breadcrumb pagename={getui(uitext, 'shopping_cart', lang)} links={[
        {
          name: getui(uitext, 'shopping_cart', lang)
        }
      ]}/>
      <>
        <StatusBar status={cartstatus} active={cart_state}/>
        <section className="container cart-detail">
          <div className="row mb-5">
            {
              cart_state === 0 &&
              <Review state={cart_state} play={(state) => {
                go_next_state(state)}}/>
            }
            {
              cart_state === 1 &&
              <Address state={cart_state} statelist={state} play={(state) => {
                go_next_state(state)}}/>
            }
            {
              cart_state === 2 &&
              <Confirm state={cart_state} payment={payment} play={(state) => {
              go_next_state(state)}}/>
            }
            {
              cart_state === 3 &&
              <Success state={cart_state} success={success} play={(state) => {
              go_next_state(state)}}/>
            }
          </div>
        </section>
      </>
        
      {/* <section className="delivery-price">
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h4 className="title">{getui(uitext, 'deli_fee', lang)}</h4>
              {
                delivery !== null &&
                delivery.map((info,key) => 
                <div className='table-wrap' key={key}>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" colSpan="5">{info.delivery_price} Ks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        _.chunk(info.carrier_states, 4).map((item, key) => 
                          <tr key={key}>{item.map((td,key) => 
                            <td key={key}>{td.state_name.slice(td.state_name.indexOf('|')+1, td.state_name.length)}</td>
                          )}</tr>
                        ) 
                      }
                    </tbody>
                  </table> 
                </div>
                )
              }
            </div>
          </div>
        </div>
      </section> */}
    </article>
  )
}
