import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import _ from 'underscore'
import currency from 'currency.js'
import { get_delivery_charges } from '../../controller'
import Input from '../../components/fromdata'
import Button from '../../components/button'
import Table from '../../components/table'
import config from '../../config'
import { Link } from 'react-router-dom'
import { township } from '../../utils/township_format'
import { get_shipping_state, getui } from '../../controller'
export default function Address({play, state, statelist}) {
  const uitext = useSelector(state => state.translate)
  const lang = useSelector(state => state.lang)
  const shopping_cart = useSelector(state => state.cart)
  const profile = useSelector(state => state.login)
  const [error, setError] = useState(null)
  const [editedProfile, setEditedProfile] = useState(
    profile === null ?
    {
      user_id: null,
      name: undefined,
      mobile: undefined,
      address1: null,
      address2: null,
      city: null,
      state_division: undefined,
      order_id: null
    } :
    {
      user_id: profile.userid,
      name: profile[0].name,
      // email: profile[0].email,
      mobile: profile[0].mobile,
      address1: profile[0].street,
      address2: "",
      city: profile[0].city,
      // zipcode: null,
      state_division: Number(profile[0].state_id),
      order_id: ""
    }
  )
  const [city, setCity] = useState(null)
  const [cities, setCities] = useState([])
  const [cityName, setCityName] = useState("")
  const [townships, setTownships] = useState([])
  const [townshipVal, setTownshipVal] = useState("")
  const [delivery, setDelivery] = useState("-")

  useEffect(() => {
    get_shipping_state()
    .then(res => {
      let city = township.township_format(res)
      setCity(city)
      setCities([ {value: '', text: 'select a city'},..._.keys(city).map(c => ({value: c, text: c}))])
      if(profile && profile[0].city !== null) {
        setTownshipVal(profile[0].state_id)
        // setTownships(cities[profile[0].cite])
        setTownships(_.map(city[profile[0].city], township => ({value: township.state_id, text: township.name})))
        get_delivery_charges({
          state_id: (profile[0].state_id)
        })
        .then(res => {
          // console.log(res, 'delivery')
          let total = _.reduce(
            _.map(shopping_cart, data => data.product_uom_qty * data.discount_price),
            (memo, price) => memo + price
          )
          let delivery_charges = res.free_over === true ? 
          total < res.free_over_amount ? res.delivery_charges : 0
          : 
          res.delivery_charges
          
          setDelivery(delivery_charges > 0 ? delivery_charges : 0)
          setEditedProfile({
            ...editedProfile,
            delivery: delivery_charges,
            // state_division: city[_.keys(city)[0]][0].state_id
          })
        })
      } else {
        setTownships([{value: '', text: 'select a township'}])
      }
    })
    if(profile) {
      profile[0].city !== null && setCityName(profile[0].city)
    }
  }, [])

  useEffect(() => {
    if(city) {
      setDelivery(0)
      setTownshipVal("")
      if(cityName === "") {
        // set township
        setTownships([{value: '', text: 'select a city'}])
      } else {
        // delivery charges on change city
        // get_delivery_charges({
        //   state_id: city[cityName][0].state_id
        // })
        // .then(res => setDelivery(res))
        // set township
        setTownships([{value: '', text: 'select a township'}, ..._.map(city[cityName], township => ({value: township.state_id, text: township.name}))])
      }
    }
  }, [cityName])
  return (
    <React.Fragment>
      <div className="col-lg-6">
        <div className="flex-title">
          <h3 className="title">{getui(uitext, 'address', lang)}</h3>
          {
            profile === null && <Link to='/login' className='cusbtn signin'>Sign In</Link>
          }
        </div>
        <div className="row">
          <div className="col-lg-6">
            <label className='required'>Name</label>
            <Input type="text" value={profile && profile[0].name} play={(e) => setEditedProfile({...editedProfile, "name": e.target.value})}/>
          </div>
          {/* <div className="col-lg-6">
            <Input type="text" label="Email" value={profile && profile[0].email} play={(e) => setEditedProfile({...editedProfile, "email": e.target.value})}/>
          </div> */}
          <div className="col-lg-6">
          <label className='required'>Phone</label>
            <Input type="number" min="0" value={profile && profile[0].mobile} play={(e) => setEditedProfile({...editedProfile, "mobile": e.target.value})}/>
          </div>
          <div className="col-lg-12">
            <label className='required'>Address</label>
            <Input type="text" value={profile && profile[0].street} play={(e) => setEditedProfile({...editedProfile, "address1": e.target.value})}/>
          </div>
          {
            city !== null &&
            <>
              <div className="col-lg-6">
                <label className='required'>City</label>
                <Input type="select"
                value={cityName}
                // options={[{value: '', text: 'select a city'}, ..._.map(city, (val, key) => ({value: key, text: key}))]}
                options={cities}
                play={
                  (e) =>{
                    setCityName(e.target.value)
                    setEditedProfile({...editedProfile, "city": e.target.value})
                    let state_id = e.target.value !== "" && city[e.target.value][0].state_id
                    if(e.target.value !== "") {
                      
                      get_delivery_charges({
                      state_id: state_id
                      }).then(res => {
                        
                        // let total = _.reduce(
                        //   _.map(shopping_cart, data => data.product_uom_qty * data.discount_price),
                        //   (memo, price) => memo + price
                        // )
                        // let delivery_charges = res.free_over === true ? 
                        // total < res.free_over_amount ? res.delivery_charges : 0
                        // : 
                        // res.delivery_charges
                        setDelivery('-')
                        setEditedProfile({
                          ...editedProfile,
                          delivery: '-',
                          state_division: ""
                        })
                      })
                    } else {
                      setEditedProfile({
                        ...editedProfile,
                        city: null,
                        state_division: 0
                      })
                    }
                  }
                }
                />
              </div>
              <div className="col-lg-6  township">
                <label className='required'>Township</label>
                <Input type="select"
                options={townships}
                // options={_.map(city[(profile[0].city)], item => ({value: item.state_id, text: item.name}))}
                value={townshipVal}
                play={(e) => {
                  let val = Number(e.target.value)
                  setTownshipVal(val)
                  setEditedProfile({...editedProfile, "state_division": val})
                  get_delivery_charges({
                    state_id: val
                  }).then(res => {
                    let total = _.reduce(
                      _.map(shopping_cart, data => data.product_uom_qty * data.discount_price),
                      (memo, price) => memo + price
                    )
                    let delivery_charges = res.free_over === true ? 
                    total < res.free_over_amount ? res.delivery_charges : 0
                    : 
                    res.delivery_charges
                    setEditedProfile({
                      ...editedProfile,
                      delivery: delivery_charges,
                      city: cityName,
                      state_division: val
                    })
                    setDelivery(delivery_charges > 0 ? delivery_charges : 0)
                  })
                }}
                />
              </div>
            </>
          }
          {/* <div className="col-lg-12">
            <Input type="number" min="0" label="Zip Code" value={profile && profile[0].zip} play={(e) => setEditedProfile({...editedProfile, "zipcode": e.target.value})}/>
          </div> */}
          <div className="col-lg-12">
            <p className='error-msg'>{error}</p>
          </div>
          <div className="col-lg-6 col-6">
            <Button
            text={getui(uitext, 'back', lang)}
            lfticn={require('../../assets/img/leftarrow-white.png')}
            play={() => {
              play(state -= 1)
              window.scrollTo(0, 0)
            }}
            />
          </div>
          <div className="col-lg-6 col-6 text-right">
            <Button
            text={getui(uitext, 'next', lang)}
            rhticn={require('../../assets/img/rightarrow-white.png')}
            play={() => {
              // editedProfile !== null ? window.localStorage.setItem('shipinfo', JSON.stringify(editedProfile)) : window.localStorage.removeItem('shipinfo')
              // if (editedProfile.name === undefined && editedProfile.mobile === undefined && editedProfile.address1 === undefined) {
              //   setError(true)
              // } else {
              //   window.localStorage.setItem('shipinfo', JSON.stringify(editedProfile))
              //   play(state += 1)
              // }
              // console.log(editedProfile.name, 'name')
              if (editedProfile.name === undefined || editedProfile.name === null ) {
                setError('Please enter your name!')
              } else if (editedProfile.mobile === undefined || editedProfile.mobile === null) {
                setError('Please enter your mobile number!')
              } else if (editedProfile.mobile < 0) {
                setError('Please enter valid mobile number!')
              } else if (editedProfile.address1 === undefined || editedProfile.address1 === null) {
                setError('Please enter your address!')
              } else if (editedProfile.state_division === 0 || editedProfile.state_division === null || editedProfile.state_division === undefined || editedProfile.state_division === "") {
                setError('Please select your city and township!')
              } else {
                window.localStorage.setItem('shipinfo', JSON.stringify(editedProfile))
                console.log(editedProfile.state_division, 'state division')
                play(state += 1)
                window.scrollTo(0, 0)
              }
            }}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="address-cart">
        <h3 className="title">{getui(uitext, 'cart_items', lang)}</h3>
        <div className="side-widget address text-right">

            <Table
              heads={["PRODUCTS", "QTY", "PRICE"]}
              body={
                shopping_cart.map((info, key) => (
                  (
                    <tr key={key}>
                      <td>
                        <div className="product">
                          <img src={info.img || require('../../assets/img/no-img.jpg')} alt={info.name} className="prduct-img" />
                          <div className="desc">
                            <p>{info.name}</p>
                            <small className="text-secondary">{_.map(info.varient_name, data => data.name).toString()}</small>
                          </div>
                        </div>
                      </td>
                      <td>
                        {info.product_uom_qty}
                      </td>
                      <td>{currency(info.discount_price * info.product_uom_qty, config.currency).format()}</td>
                    </tr>
                  )
                ))
              }
            />
            <ul className="pricelist">
              <li>
                <span className="totalspan">Subtotal : </span> <span className="currency">{
                  currency(_.reduce(
                    _.map(shopping_cart, data => data.product_uom_qty * data.discount_price),
                    (memo, price) => memo + price
                  ), config.currency).format()
                }</span>

              </li>
              <li >
                
                <span className="totalspan">
                  {
                    delivery === "-" ? "Delivery Charge" : Number(delivery) > 0 ? "Delivery Charges : " : "Delivery Charge (Free) : "
                  }
                </span> 
                <span className="currency">
                {
                  delivery === "-" ? "-" : currency(delivery, config.currency).format()
                }
                </span>
              </li>
              <li >
                <span className="totalspan">Total : </span> <span className="currency">{
                  currency((_.reduce(
                    _.map(shopping_cart, data => data.product_uom_qty * data.discount_price),
                    (memo, price) => memo + price
                  ) + (delivery === "-" ? 0 : delivery)), config.currency).format()
                }</span>
              </li>

            </ul>
          </div>

        </div>

      </div>
    </React.Fragment>
  )
}
