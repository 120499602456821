export default [
  {
    id: 'C-0000001',
    date: '19/06/2020',
    item: 'Baby Towel Baby bottle',
    total: 'KS 50,000',
    status: 'Pending',
    shipto: 'No.97, xxxxxxxxx Street, Lanmadaw, Yangon',
    url: '/'
  },
  {
    id: 'C-0000001',
    date: '19/06/2020',
    item: 'Baby Towel Baby bottle',
    total: 'KS 50,000',
    status: 'Pending',
    shipto: 'No.97, xxxxxxxxx Street, Lanmadaw, Yangon',
    url: '/'
  }
]