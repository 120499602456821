const config = require('./_config')
const axios = require('axios')

export const get_policy = (param) => {
  return axios.get(`${config.default.url}/privacy/policy`, {
    params: {
      ...param
    }
  })
  .then((res) => res.data.data)
}