import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'underscore'
import currency from 'currency.js'
import config from '../../config/index'
import { useDispatch } from 'react-redux'
import { ins, open_popup } from '../../redux-actions/action'
import {addToCart} from '../../utils/add_to_cart'
import {calc_percent} from '../../utils/discount_calc'
import ImgFake from '../fakeimg'
export default function Card({
  info, 
  custom_class='col-lg-4 col-md-4 col-6 card-col'
}) {
  const dispatch = useDispatch()
  let keyclass = "card-" + Math.floor(Math.random() * 1000)
  // console.log(info.product_image, '....')
  return (
    <div className={`${custom_class} ${keyclass} ${info.status === "out_of_stock" && "card-disable"}`}>
      <div className='card-default'>
        {info.status === "out_of_stock" &&  <span className="outstock">out of stock</span>}
        <Link to={`/product/${encodeURIComponent(info.product_id)}/${encodeURIComponent(info.name)}`}>
          <figure>
            {/* <div className='card-img' style={{background: "url("+ ( info.img || info.product_image || require('../../assets/img/no-img.jpg') )+") no-repeat center/contain"}}> */}
            <div className='card-img' style={{background: `url('${info.img || info.product_image || require('../../assets/img/no-img.jpg')}') no-repeat center / cover`}}>
              <ImgFake width={24} height={24} />
            </div>
          </figure>
          <div className='card-info'>
            <h3>{info.name || info.produc}</h3>
            <div className='card-info-bottom'>
              <p>
                {currency(calc_percent(
                (info.price || info.list_price), 100 - info.percentage),
                config.currency).format() + ' '}
                {info.percentage > 0 && 
                <small className="normal-price text-secondary">
                  <del>
                      {currency(info.price || info.list_price, config.currency).format()}
                  </del>
                  <span></span>
                </small>}
              </p>
            </div>
          </div>
        </Link>
        {
          info.status !== "out_of_stock" &&
          <div className='addcart' onClick={(event) => window.location.href = `/product/${info.product_id}/${info.name}`
          //   addToCart(info.id || info.product_id).then(res => {
          //   let data = {
          //     description: res.description,
          //     img: res.url_link,
          //     name: res.name,
          //     product_id: (info.id || info.product_id).toString(),
          //     product_uom_qty: 1,
          //     tax: "",
          //     varient_id: _.map(res.selected_varient, data => data.id).toString(),
          //     varient_name: res.selected_varient,
          //     percentage: info.percentage
          //   }
          //   data.price = _.reduce(
          //     _.map(res.selected_varient, data => data.price),
          //     (memo, num) => memo + num, info.price || res.list_price)
          //   data.discount_price = calc_percent(data.price, 100 - data.percentage) 
          //   dispatch(ins(data))
          //   // if(res !== 'out of stock') {
          //   //   let data = {
          //   //     description: res.description,
          //   //     img: res.url_link,
          //   //     name: res.name,
          //   //     product_id: (info.id || info.product_id).toString(),
          //   //     product_uom_qty: 1,
          //   //     tax: "",
          //   //     varient_id: _.map(res.selected_varient, data => data.id).toString(),
          //   //     varient_name: res.selected_varient,
          //   //     percentage: info.percentage
          //   //   }
          //   //   data.price = _.reduce(
          //   //     _.map(res.selected_varient, data => data.price),
          //   //     (memo, num) => memo + num, info.price || res.list_price)
          //   //   data.discount_price = calc_percent(data.price, 100 - data.percentage) 
          //   //   dispatch(ins(data))
          //   // } else {
          //   //   dispatch(open_popup(
          //   //     {content : 
          //   //       <div className="alert-out-of-stock">
          //   //         <h2>This item is out of stock.</h2>
          //   //         <div className="mt-4">
          //   //           <div className="row">
          //   //             <div className="col-lg-4">
          //   //               <img src={info.product_image} alt={info.product_id} className="img-popup"/>
          //   //             </div>
          //   //             <div className="col-lg-8">
          //   //               <h3>Product Title</h3>
          //   //               <p></p>
          //   //             </div>
          //   //           </div>
          //   //         </div>
          //   //       </div>
          //   //     }
          //   //   ))
          //   // }
          // })
          }>
            <img src={require('../../assets/img/supermarket.svg')} alt='cart' />
          </div>
        }
      </div>
    </div>
  )
}

Card.propTypes = {
  info: PropTypes.object.isRequired
}
