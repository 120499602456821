import React, { useState, useEffect } from 'react'
import { get_banner } from '../../controller'
import Slider from "react-slick"
export default function Banner() {
  const [ banner, setBanner ] = useState(null);
  useEffect(() => {
    get_banner()
    .then(res => setBanner(res))
  }, [setBanner])
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <Slider {...settings}>
    {
      banner !== null && banner.length > 0 &&
      banner.map((info,key) =>
        <div className='banner-item' key={key}>
          <img src={info.slider_images} alt={info.name} />
        </div>
      )
    }
    </Slider>
  )
}
