import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Card from '../../components/card'
import './style.scss'

// custom component
import Sidebar from '../../components/sidebar'
import SecTitle from '../../components/sectiontitle'
import Breadcrumb from '../../components/breadcrumb'
import Pagination from 'rc-pagination';
import {isMobile} from 'react-device-detect';
import {get_webgallerydetail, getui} from '../../controller'

export default function Gallery() {
    const [currentPage, setCurrentPage] = useState(1)
    const uitext = useSelector(state => state.translate)
    const lang = useSelector(state => state.lang)
    const [ gallery, setgallery ] = useState(null)
    let id = useParams().galleryid
    // let galleryTitle = id.replace("_", " ")
    let latestItem = getui(uitext, 'latest_products', lang)
    let hotItem = getui(uitext, 'hottest_items', lang)
    let post = gallery !== null && gallery.length
    let postSize = 9
    if (isMobile) {
        postSize = 8
    }
    // window.location.reload()
    useEffect(() => {
        get_webgallerydetail({
            group_code : id,
            lang: lang
        })
        .then(res => setgallery(res))
        setCurrentPage(1)
        window.scrollTo(0, 0)
    }, [id])

    // console.log(post)

    return (
        <article id="latestproduct">
            <Breadcrumb pagename={getui(uitext, 'products', lang)} links={
                [
                    {
                        name: getui(uitext, 'products', lang)
                    }
                ]
            }/>
            <div className="container productsection">
                <div className="row">
                    <div className="col-lg-3 brand-sidebar">
                        <Sidebar />
                    </div>
                    <div className="col-lg-9">
                        <SecTitle text={id === 'latest_items' ? latestItem : hotItem}/>
                            <div className='row card-row latestitems'>
                                {
                                    gallery !== null && gallery.length > 0 &&
                                    gallery.slice((postSize * currentPage) - postSize, postSize * currentPage).map((info,key) => <Card info={info} key={key} />)
                                }
                            </div>
                            {
                                gallery !== null && gallery.length > 9 &&
                                <div className='pagi-wrap'>
                                    <Pagination
                                    onChange={(current) => {
                                        setCurrentPage(current)
                                        window.scrollTo(0, 0)
                                    } }
                                    current={currentPage}
                                    total={post}
                                    defaultPageSize={postSize}
                                    />
                                </div>
                            }
                    </div>
                </div>
            </div>
        </article>
    )
}

