import { BrowserRouter, Route, Switch } from 'react-router-dom'
import React, {Suspense} from 'react'
import Loading from '../components/loading'
import Header from '../components/header'
import Footer from '../components/footer'
import RouteList from './routelist'
export default function AppRoute() {
    return (
        <BrowserRouter>
            <Header />
            <Suspense fallback={<Loading />}>
                <Switch>
                    {
                        RouteList.map(({path, component}, id) => <Route exact path={path} component={component} key={id}/>)
                    }
                </Switch>
            </Suspense>
            <Footer />
        </BrowserRouter>
    )
}
