const _ = require('underscore')
const township_format = (data) => {
  let collection = {}
  _.map(data, item => {
    let res = item.name.split('|')
    collection[res[0]] = []
  })
  _.map(data, item => {
    let res = item.name.split('|')
    item.name = res[1]
    collection[res[0]].push(item)
  })
  return collection
}
const townshipinfo = (name, data) => _.find(data, item => item.name === name)
export const township = { township_format, townshipinfo }
