import config from './_config'
const axios = require('axios')

export const post_profile = (data, token) => {
  return axios.create({
    headers: {
      'access-token': token
    }
  })
  .post(`${config.url}/save/profile/information`, data)
  .then(res => res.data.result)
  .catch(err => err)
}