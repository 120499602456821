
export default [
    {
        name: 'facebook',
        url: '/',
        img: require('../assets/img/facebook.png')
    },
    {
        name: 'twitter',
        url: '/',
        img: require('../assets/img/twitter.png')
    },
    {
        name: 'instagram',
        url: '/',
        img: require('../assets/img/instagram.png')
    }
]