export default {
  data: [
    {
      patch: '/',
      img: require('../assets/img/brand.png')
    },
    {
      patch: '/',
      img: require('../assets/img/brand.png')
    },
    { 
      patch: '/',
      img: require('../assets/img/brand.png')
    },
    { 
      patch: '/',
      img: require('../assets/img/brand.png')
    },
    { 
      patch: '/',
      img: require('../assets/img/brand.png')
    },
    { 
      patch: '/',
      img: require('../assets/img/brand.png')
    },
    { 
      patch: '/',
      img: require('../assets/img/brand.png')
    },
    { 
      patch: '/',
      img: require('../assets/img/brand.png')
    },
    { 
      patch: '/',
      img: require('../assets/img/brand.png')
    },
    {
      patch: '/',
      img: require('../assets/img/brand.png')
    }
  ]
}