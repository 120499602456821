// react
import React, {useState} from 'react'
import { Link, useLocation } from 'react-router-dom'
// redux
import { useSelector, useDispatch } from 'react-redux'
import { del, update, open_popup } from '../../redux-actions/action'
// utality
import _ from 'underscore'
import currency from 'currency.js'
// config
import config from '../../config'
// components
import Spanner from '../spanner'
import Button from '../button'
import { get_productqty, getui } from '../../controller'
import { calc_percent } from '../../utils/discount_calc'

export default function NavCart() {
  const dispatch = useDispatch()
  const cartItems = useSelector(state => state.cart)
  const uitext = useSelector(state => state.translate)
  const lang = useSelector(state => state.lang)
  // const [visibility, setVisibility] = useState(false)
  const location = useLocation()
  
  return (
    <div className="cart">
      {/* <figure onClick={() => setVisibility(!visibility)}> */}
      <figure>
        {cartItems.length > 0 && <span className="badge">{
          _.reduce(
            _.map(cartItems, item => item.product_uom_qty), (memo, cur) => memo + cur
          )
        }</span>}
        <img src={require('../../assets/img/cart-green.png')} alt="sb baby cart" />
        <figcaption>{getui(uitext, 'my_cart', lang)}</figcaption>
      </figure>
      <div className="cart-wrapper"></div>
      {/* <div className={"cart-popup " + (!visibility && 'd-none')}> */}
      {
        location.pathname !== "/cart" &&
        <div className="cart-popup">
          <div className="cart-content">
            {
              cartItems.length > 0 &&
              <>
                <div className="cart-header mb-4">
                  <h2>{getui(uitext, 'my_cart', lang)}</h2>
                  <Link to='/cart'>Go shoppingcart detail</Link>
                </div>
                <table className="table">
                  <tbody>
                    {
                      cartItems.slice(0, 4).map((item, key) =>
                        <tr key={key}>
                          <td>
                            <img src={item.img || require('../../assets/img/no-img.jpg')} alt={item.name} className="product" title={item.name}/>
                          </td>
                          {
                            item.name !== null &&
                            <td>
                              {item.name.length > 8 ? item.name.slice(0, 8) + '...' : item.name}<br />
                              <small className="text-secondary">{_.map(item.varient_name, data => data.name).toString()}</small>
                            </td>
                          }
                          <td>
                            <Spanner 
                            change={(e) => {
                              get_productqty({
                                product_id: item.product_id,
                                variant_id: item.varient_id,
                                qty: e
                              }).then((res) => {
                                if(res.instock === true) {
                                  dispatch(update({...item, product_uom_qty: e}))
                                } else {
                                  dispatch(open_popup(
                                    {content : 
                                      <div className="alert-out-of-stock">
                                        <h2>This item is out of stock.</h2>
                                      </div>
                                    }
                                  ))
                                }
                              })
                              }
                            }
                            value={item.product_uom_qty} />
                          </td>
                          <td>
                            {' ' + currency(
                              calc_percent(item.price * item.product_uom_qty, 100 - item.percentage), 
                              config.currency
                            ).format()}
                          </td>
                          <td>
                            <img 
                            onClick={() => dispatch(del(item))}
                            className="del" 
                            src={require('../../assets/img/delete-button.png')}
                            alt="delete sbbaby"
                            />
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </>
            }
            {
              cartItems.length === 0 &&
              <div className="noitem">
                {/* <img className="closecross" src={require('../../assets/img/close1.png')} alt="no item" onClick={() => setVisibility(false)} /> */}
                {/* <img className="closecross" src={require('../../assets/img/close1.png')} alt="no item" /> */}
                <img src={require('../../assets/img/empty.png')} alt="no item" />
                <br/>
                <h5>Your cart is empty!</h5>
                <Button 
                play={() => window.location.href = '/'}
                text="continue shopping" />
              </div>
            }
          </div>
        </div>
      
      }
    </div>
  )
}
