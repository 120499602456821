import React, { useState, useEffect } from 'react'
import Button from '../../components/button'
import Input from '../../components/fromdata'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './style.scss'
import { post_resetpass, getui } from '../../controller'

export default function Resetpassword() {
    const uitext = useSelector(state => state.translate)
    const lang = useSelector(state => state.lang)
    const mobile = useSelector(state => state.mobile)
    const getotp = useSelector(state => state.getotp)
    // const profile = useSelector(state => state.login)
    const [ password, setpassword ] = useState(null)
    const [error, setError] = useState(null)
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    const handleChange = (e) => {
        setpassword({
            ...password,
            [e.target.id]: e.target.value
        })
    }
    // let dispatch = useDispatch()
    // const loginSuccess = (token, uid, expires) => {
    //     let userinfo = {
    //         token,
    //         uid,
    //         expires
    //         // ...res[0]
    //     }
    //     // dispatch(login_action(userinfo))
    //     // console.log(userinfo)
    //     // localStorage.setItem('userinfo', JSON.stringify(userinfo))
    //     // window.location.href = '/'
    // }

    // const removeLogin = () => {
    //   localStorage.removeItem('login')
    //   window.location.href= window.location.origin+ '/login'
    // }
    
    // const Logout = () => {
    //   let token = profile.access_token
    //   delete_logout(token)
    //   .then(res => {
    //     let logout_data = res[0].delete
    //     logout_data === true ?
    //     removeLogin() :
    //     console.log(res)
    //   })
    // }

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
          otp: getotp,
          mobile,
          ...password
        }
        // console.log(data)
        data.password === data.conpass ?
        post_resetpass(data)
        .then((res) => {
            // console.log(res.status)
            res.status === 'success' ?
            window.location.href = '/success/resetpass' :
            setError(res.message)
        })
        :
        setError("Please make sure your password match!")
    }
    
    return (
      <article id="otp">
        <section className="bg-form">
          <div className="container form-container">   
            <div className="row">
              <div className='col-12'>
                <h3>{getui(uitext, 'reset_pass', lang)}</h3>
                <div className="formsection">
                  <form onSubmit={handleSubmit}>
                    <div className="form-password">
                      <p>New Password</p>
                      <Input id="password" type="password" placeholder="New Password" play={(e) => handleChange(e)} required />
                    </div>
                    <div className="form-password">
                      <p>Confirm New Password</p>
                      <Input id="conpass" type="password" placeholder="Confirm New Password" play={(e) => handleChange(e)} required />
                    </div>
                    <p className='error'>{error}</p>
                    <Button
                      text='Submit'
                      addclass="btn-checkout btn-login"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
    )
}