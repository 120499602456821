import React, {useState} from 'react'
export default function Step({step, setStep}) {
  const [ active, setactive ] = useState(null)
  return (
    <ul className="product-step">
      {
        step !== null &&
        step.map((step,key) => {
          return (
            <li 
            key={key} 
            className={'step-link ' + (active === key ? 'active': '')}
            onClick={() => {
              setStep(key === active ? null : step.id)
              key === active ? setactive(null) : setactive(key)
              window.scrollTo(0, document.querySelector('.step-result')?.offsetTop)
            }}
            >{step.english}</li>
          )
        })
      }
    </ul>
  )
}
