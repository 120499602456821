import React, { useEffect } from 'react'
import Promo from '../../components/promotion'
import BreadCrumb from '../../components/breadcrumb'
import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { get_prodpricelist, getui } from '../../controller'
import './style.scss'

const requestPromo = async (data) => await get_prodpricelist(data)

export default function Promodiscount() {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    const lang = useSelector(state => state.lang)
    const uitext = useSelector(state => state.translate)

    const {data: promos, status: promoStatus} = useQuery(['promo_items', {
        lang
    }], requestPromo)
    
    return (
        <div id="promodiscount">
            <BreadCrumb pagename={getui(uitext, 'promo_discount', lang)} links={[{name: 'promo_discount'}]}/>
            <div className='promoitem'>
              <div className="container">
                  <div className="row">
                      {
                        promoStatus === 'success' &&
                        promos.map((promo, key) => (
                        <div className='col-lg-4 col-md-4 col-6' key={key}>
                          <figure>
                            <Promo img={promo.promo_image} url={promo.id} />
                          </figure>
                        </div>
                        ))
                      }                    
                  </div>
              </div>
            </div>
        </div>
    )
}
