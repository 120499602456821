export default [
    {
        title   :   'Delivery',
        desc    :   'Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor .',
        img     :   require('../assets/img/truck.png')
    },
    {
        title   :   'Payment System',
        desc    :   'Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor .',
        img     :   require('../assets/img/money.png')
    },
    {
        title   :   'Customer Service',
        desc    :   'Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor .',
        img     :   require('../assets/img/technical-support.png')
    }
]