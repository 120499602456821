import React, { useState, useEffect } from 'react'
import Button from '../../components/button'
import Input from '../../components/fromdata'
import { useDispatch, useSelector } from 'react-redux'
import { mobile } from '../../redux-actions/action'
import { Link } from 'react-router-dom'
import './style.scss'
import { post_signup, get_state, getui } from '../../controller'
import {township} from '../../utils/township_format'
import _ from 'underscore'

export default function Signup() {
    const uitext = useSelector(state => state.translate)
    const lang = useSelector(state => state.lang)
    const login = useSelector(state => state.login)
    const [signup, setSignup] = useState({})
    const [error, setError] = useState(null)
    const [city, setCity] = useState(null)
    const [cityName, setCityName] = useState(null)
    const [townshipVal, setTownshipVal] = useState('')

    useEffect(() => {
      //get state list
      get_state()
      .then(res => {
        let city = township.township_format(res)
        setCity(city)
        setSignup({
          city: 'Yangon',
          state_id: city[_.keys(city)[0]][0].state_id
        })
      })
      window.scrollTo(0, 0)
    }, [])
    const handleChange = (e, id) => {
      let data = {
        ...signup,
        [e.target.id]: e.target.id === 'state_id' ? Number(e.target.value) : e.target.value
      }
      id && (data = {...data, state_id: Number(id)})
      setSignup(data)
      // setSignup({
      //   ...signup,
      //   [e.target.id]: e.target.id === 'state_id' ? Number(e.target.value) : e.target.value
      // })
    }

    let dispatch = useDispatch()
    const signupSuccess = () => {
      dispatch(mobile(signup.mobile))
      window.location.href= window.location.origin + `/otp/signup`
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(signup)
        signup.password === signup.conpass ?
        post_signup(signup)
        .then((res) => {
            res.status === 'success' ?
            signupSuccess() :
            setError(res.message)
        })
        :
        setError("Please make sure your password match!")
    }
    if (login !== null) window.location.href='/'
    return (
        <article id="signup">
          <section className="bg-form">
            <div className="container form-container ">   
              <div className="formsection">
                  <h2>{getui(uitext, 'sign_up', lang)}</h2>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-name">
                          <p className='required'>Username</p>
                          <Input id="name" type="text" placeholder="Username" play={(e) => handleChange(e)} required/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-name">
                          <p className='required'>Mobile</p>
                          <Input id="mobile" min="0" type="number" placeholder="Phone" play={(e) => handleChange(e)} required/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-password">
                          <p className='required'>Password</p>                                
                          <Input id="password" type="password" placeholder="Password" play={(e) => handleChange(e)} required/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-password">
                          <p className='required'>Confirm Password</p>
                          <Input id="conpass" type="password" placeholder="Confirm Password" play={(e) => handleChange(e)}/>
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-name">
                          <p>Email</p>
                          <Input id="email" type="email" placeholder="Email" play={(e) => handleChange(e)} />
                        </div>
                      </div> */}
                      {
                        city !== null &&
                        <>
                          <div className="col-md-6">
                            <div className="form-password">
                              <p className='required'>City</p>
                              <Input type="select" 
                                required
                                id="city"
                                options={[{value: "", text: "select a city"}, ..._.map(city, (val, key) => ({value: key, text: key}))]} 
                                play={
                                  (e) =>{
                                    setCityName(e.target.value)
                                    e.target.value !== "" && handleChange(e, city[e.target.value][0].state_id)
                                  }
                                }
                                />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-password">
                              <p className='required'>Township</p>
                              <Input type="select"
                                required
                                id="state_id"
                                options={[{value: '', text: 'select a township'} , ..._.map(city[cityName], item => ({value: item.state_id, text: item.name}))]} 
                                value={townshipVal}
                                play={(e) => {
                                  setTownshipVal(e.target.value)
                                  handleChange(e)}}
                                />
                            </div>
                          </div>
                        </>
                      }
                      {/* <div className="col-md-6">
                        <div className="form-password">
                          <p>Zip</p>
                          <Input id="zip" min="0" type="number" placeholder="Zip" play={(e) => handleChange(e)} />
                        </div>
                      </div> */}
                      <div className="col-md-12">
                        <div className="form-password">
                          <p>Street</p>
                          <Input id="street" type="text" placeholder="Street" play={(e) => handleChange(e)} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <p className='error'>{error}</p>
                      </div>
                      <div className="col-md-3">
                        <Button  
                          text="Sign Up"            
                          addclass="btn-checkout btn-login"
                        />
                      </div>
                      <div className="col-md-9">
                        <div className='already'>
                          <p>Already have an account?</p>
                          <Link to='/login'>Login</Link>
                        </div>
                      </div>
                    </div>
                  </form>
              </div>
            </div>
          </section>
        </article>
    )
}